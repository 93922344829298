import request from '@/utils/request';
//登录
export async function login(data) {
  return await request.post('/hx/user/pclogin', data, true);
}

//退出
export async function logout(data) {
  return await request.get('/hx/user/pcLogout', data, true);
}

//通用枚举数据字典
export async function getDictionary(data) {
  return await request.get('/hx/dictionary/batch', data);
}

//下级枚举数据字典
export async function getDictionaryChild(data) {
  return await request.get('/hx/dictionary/children', data);
}

//通用数据字典删除
export async function delDictionary(data) {
  return await request.reqDelete('/hx/dictionary/delete', data);
}

//通用数据字典-新增
export async function addDictionary(data) {
  return await request.post('/hx/dictionary/save', data);
}

//通用数据字典-修改
export async function editDictionary(data) {
  return await request.put('/hx/dictionary/update', data);
}

//图片上传
export async function commonUpload(data) {
  return await request.post('/hx/file/upload', data, false, true);
}

//查询系统设置
export async function getSystem(data) {
  return await request.get('/hx/dictionary/one', data);
}

//修改系统设置
export async function updateSystemSet(data) {
  return await request.patch('/hx/dictionary/updateSystemSet', data);
}

export async function commonDelete(url, data) {
  return await request.delete(url, data);
}
export async function commonPost(url, data, isForm) {
  return await request.post(url, data, isForm);
}
export async function commonGet(url, data) {
  return await request.get(url, data);
}
