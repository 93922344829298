export const page = {
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
    };
  },
  methods: {
    searchForm() {
      this.pageNum = 1;
      this.onSearch();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.onSearch();
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.onSearch();
    },
    getPager() {
      if (pageNum === 1) {
        return {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
      }
      let totalPage = Math.ceil((this.total - 1) / this.pageSize);
      return {
        pageNum: this.pageNum > totalPage ? totalPage : this.pageNum,
        pageSize: this.pageSize,
      };
    },
  },
};
