<!--
 * @Author: huangpiao35 huangpiao35@zto.com
 * @Date: 2024-06-20 20:39:45
 * @LastEditors: huangpiao35 huangpiao35@zto.com
 * @LastEditTime: 2024-11-05 21:43:16
 * @FilePath: \hongxin-admin-web\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted(){
    const userInfo = window.localStorage.getItem('userInfo')
    this.$store.commit("SET_USER_INFO", userInfo && JSON.parse(userInfo));
  },
}
</script>

<style lang="scss">
@import "@/styles/index.scss";
.BMap_bubble_title{
  font-size: 22px;
}
</style>
