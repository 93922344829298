/**
 * request工具方法
 * 兼容网关请求，宝盒网关请求
 */
import axios from 'axios';
import qs from 'qs';
import { Message, Loading } from 'element-ui';
import { HostName } from '@/configs/env';
import router from '@/router';
// import { getToken } from './auth'
// import store from '@/store'
const HTTP_ERROR_MSG = '请求超时，请重试';
axios.defaults.withCredentials = true;
// axios.defaults.headers.common['Cookie'] = ''
let loading; // 定义loading变量
function startLoading() {
  loading = Loading.service({
    lock: true,
    background: 'rgba(0, 0, 0, 0.6)',
  });
}

function endLoading() {
  loading.close();
}

function createService() {
  const axiosConfig = {
    baseURL: HostName, //存在一些场景，调用多个后端地址
    // timeout: 5000, // 超时时间
    withCredentials: true, // 允许携带cookie
  };

  return axios.create(axiosConfig);
}

/**
 * 创建axios实例
 */
const service = createService();

// 请求2秒内的错误只提示一次
const errormsg = function (text) {
  Message.error({
    message: text,
    type: 'error',
    duration: 3000,
    showClose: true,
  });
};

axios.interceptors.request.use(
  (config) => {
    // if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    // config.headers['COOKIE'] = getToken()
    // }
    startLoading();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response拦截器
axios.interceptors.response.use(
  (response) => {
    endLoading();
    const res = response.data;
    if (res.code === 401) {
      router.push('/login');
    }
    // 请求成功
    return response;
  },
  (error) => {
    // 无权限
    console.log(error);
    if (error.response.status === 401) {
      router.push('/login');
    } else if (error.response.status === 500) {
      errormsg('服务不可用');
    } else {
      console.log(`repsones error: ${error}`); // for debug
      errormsg(error.response.data.msg);
    }
    return Promise.reject(error);
  }
);

/**
 * get 请求
 * @param url
 * @param params
 */
async function get(url, params, isQs, isFormData) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then(
        (response) => {
          if (response.data.code !== 200) {
            errormsg(response.data.msg || response.message || '');
          }
          resolve({ status: response.data.code === 200, ...response.data });
        },
        (err) => {
          if (!err.data.status) {
            errormsg(err.data.message || err.message || HTTP_ERROR_MSG);
          }
          reject(err);
        }
      )
      .catch((error) => {
        errormsg(HTTP_ERROR_MSG);
        reject(error);
      });
  });
  // return await request({ url, params, method: 'get' })
}

/**
 * post 请求
 * @param url
 * @param requestData
 */
async function post(url, params, isQs, isFormData) {
  if (isFormData) {
    axios.defaults.headers['Content-Type'] = 'multipart/form-data';
  }
  // return await request({ url, data: requestData, method: 'post' })
  if (isQs) {
    params = qs.stringify(params);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(
        (response) => {
          if (response.data.code !== 200) {
            errormsg(response.data.msg || response.message || '');
          }
          resolve({ status: response.data.code === 200, ...response.data });
        },
        (err) => {
          if (!err.data.status) {
            errormsg(err.data.message || err.message || HTTP_ERROR_MSG);
          }
          reject(err.data);
        }
      )
      .catch((error) => {
        if (!document.querySelector('.z-message--error')) {
          errormsg(HTTP_ERROR_MSG);
        }
        reject(error);
      });
  });
}

async function patch(url, params, isQs) {
  // if (isForm) {
  // 	axios.defaults.headers['Content-Type'] = 'multipart/form-data'
  // }
  // return await request({ url, data: requestData, method: 'post' })
  if (isQs) {
    params = qs.stringify(params);
  }
  return new Promise((resolve, reject) => {
    axios
      .patch(url, params)
      .then(
        (response) => {
          if (response.data.code !== 200) {
            errormsg(response.data.msg || response.message || '');
          }
          resolve({ status: response.data.code === 200, ...response.data });
        },
        (err) => {
          if (!err.data.status) {
            errormsg(err.data.message || err.message || HTTP_ERROR_MSG);
          }
          reject(err.data);
        }
      )
      .catch((error) => {
        if (!document.querySelector('.z-message--error')) {
          errormsg(HTTP_ERROR_MSG);
        }
        reject(error);
      });
  });
}

async function put(url, params, isQs) {
  // if (isForm) {
  // 	axios.defaults.headers['Content-Type'] = 'multipart/form-data'
  // }
  // return await request({ url, data: requestData, method: 'post' })
  if (isQs) {
    params = qs.stringify(params);
  }
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then(
        (response) => {
          if (response.data.code !== 200) {
            errormsg(response.data.msg || response.message || '');
          }
          resolve({ status: response.data.code === 200, ...response.data });
        },
        (err) => {
          if (!err.data.status) {
            errormsg(err.data.message || err.message || HTTP_ERROR_MSG);
          }
          reject(err.data);
        }
      )
      .catch((error) => {
        if (!document.querySelector('.z-message--error')) {
          errormsg(HTTP_ERROR_MSG);
        }
        reject(error);
      });
  });
}

async function reqDelete(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, {
        params: params,
      })
      .then(
        (response) => {
          if (response.data.code !== 200) {
            errormsg(response.data.msg || response.message || '');
          }

          resolve({ status: response.data.code === 200, ...response.data });
        },
        (err) => {
          if (err.data && !err.data.status) {
            errormsg(err.data.message || err.message || HTTP_ERROR_MSG);
          }
          reject(err);
        }
      )
      .catch((error) => {
        errormsg(HTTP_ERROR_MSG);
        reject(error);
      });
  });
  // return await request({ url: url, data: requestData, method: 'delete' })
}
// async function request(config) {
// 	// const { data } = await service(config)
// 	// return data
// 	const { method } = config
// 	return new Promise((resolve, reject) => {

// 	})
// }

// request.post = post
// request.get = get
// request.delete = reqDelete

export default {
  get,
  post,
  reqDelete,
  patch,
  put,
};
