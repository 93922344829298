<template>
  <header
    id="c-header"
    class="c-header-nav"
    :class="isCollapse ? 'collapse' : ''"
  >
    <div class="header-layout">
      <div class="u-flex u-flex-shrink-0">
        <span class="iconfont icon-ego-menu" @click="toggleSideBar"></span>

        <el-breadcrumb separator="/" class="c-breadcrumb">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            v-for="(item, index) in activeParentList"
            :key="item.menuKey + index"
            ><a
              v-if="activeParentList.length - 1 !== index"
              :href="item.menuKey"
              >{{ item.label }}</a
            >
            <span v-else>{{ item.label }}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="u-flex u-flex-shrink-0">
        <!-- <div class="header-menu"></div> -->

        <!-- <el-tooltip
        content="点击切换全屏"
        :manual="false"
        class="item"
        effect="dark"
        placement="bottom"
      >
        <div class="u-flex-center c-header-nav__warn" @click="handleFullScreen">
          <i class="el-icon-full-screen"></i>
        </div>
      </el-tooltip> -->
        <!-- <el-tooltip
          content="消息"
          :manual="false"
          class="item"
          effect="dark"
          placement="bottom"
        > 
        <div class="u-flex-center c-header-nav__warn" @click="setMessage">
          <el-badge :value="12" class="item">
              <i class="el-icon-bell"></i>
          </el-badge>
        </div>
        </el-tooltip> -->
        <el-tooltip
          content="其他设置"
          :manual="false"
          class="item"
          effect="dark"
          placement="bottom"
        >
          <div class="u-flex-center c-header-nav__warn" @click="setLayout">
            <span class="iconfont icon-shezhi-xianxing"></span>
          </div>
        </el-tooltip>

        <el-popover
          placement="bottom"
          popper-class="c-header-nav__user"
          trigger="click"
        >
          <div class="user__info" @click="viewInfo">
            <div class="user__logout">
              <span class="list-text">个人信息</span>
            </div>
          </div>
          <div class="user__info" @click="logout">
            <div class="user__logout">
              <span class="list-text">退出登录</span>
            </div>
          </div>

          <!-- <div class="layout__config" @click="setLayout">
          <span class="list-text">其他设置</span>
        </div> -->
          <div slot="reference" class="c-header-nav__popover">
            <img class="c-header-nav__avatar" :src="userInfo.img || avatar" />
            <span class="u-flex-center c-header-nav__name c-header__logout"
              >{{ userInfo.name }}
            </span>
          </div>
        </el-popover>
      </div>
    </div>

    <el-drawer
      title="其他设置"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <el-row>
        <el-col :span="24">
          <div class="moreset-title">主题配色</div>
          <div class="select-color-content">
            <div
              class="select-color-item"
              v-for="(item, index) in colorList"
              :key="index"
              :class="[
                item.color === getDefaultColor ? ' el-icon-check el-icon' : '',
              ]"
              :style="{ backgroundColor: item.color }"
              @click="changeTheme(item)"
            ></div>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="moreset-title">更多设置</div>
          <div class="select-content">
            <div class="u-flex">
              <el-switch v-model="isSetCommonSkin" @change="changeHeaderSkin">
              </el-switch>
              <span>通色</span>
            </div>
          </div>
          <div class="select-content">
            <div class="u-flex">
              <el-switch v-model="isSetCommonIcon" @change="changeIconSkin">
              </el-switch>
              <span>通栏</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
    <el-drawer
      title="其他设置"
      :visible.sync="messageDialog"
      :direction="direction"
      :before-close="
        () => {
          messageDialog = false;
        }
      "
    >
    </el-drawer>
    <div class="rightStatus">
      <audio ref="audioVo" hidden>
        <source :src="preview" type="audio/mp3" />
      </audio>
      <audio ref="audioVo2" hidden>
        <source :src="cancel" type="audio/mp3" />
      </audio>
    </div>
  </header>
</template>

<script>
import preview from '@/assets/preview.mp3';
import cancel from '@/assets/cancel.mp3';
import Utils from '@/utils/utils';
import screenfull from 'screenfull';
import avatar from '@/assets/header-avatar.jpg';
import { menus } from '@/router/menus';
import { mapState, mapActions, mapGetters } from 'vuex';
import GLOBAL from '@/configs/global';
import io from 'socket.io-client';
import moment from 'moment';
let socket = null;
let limitConnect = 10;
let timeConnect = 0
export default {
  name: 'CHeader',
  data() {
    return {
      preview,
      cancel,
      avatar,
      date: '',
      drawer: false,
      messageDialog: false,
      direction: 'rtl',
      isSetCommonSkin: false,
      isSetCommonIcon: false,
      colorList: [
        { theme: 'blue', color: '#2d8cf0', rgb: '57,99,188' },
        { theme: 'green', color: '#36b368', rgb: '57,99,188' },
        { theme: 'origin', color: '#f6ad55', rgb: '57,99,188' },
        { theme: 'red', color: '#f56c6c', rgb: '57,99,188' },
        { theme: 'deepblue', color: '#3bc0c3', rgb: '57,99,188' },
        { theme: 'main', color: '#667eea', rgb: '57,99,188' },
      ],
      activeParentList: [],
      msgdata: '',
      data: '',
      timer: null,
    };
  },

  mounted() {
    this.$confirm('开启接单声音提示', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      showCancelButton: false,
      type: 'warning',
    })
      .then(() => {
        //根据当前路由定位菜单信息
        this.connectWebSocket();
      })
      .catch(() => {});
  },
  beforeDestroy() {
    this.closeWebsocket()
  },
  computed: {
    ...mapGetters(['getDefaultColor', 'isDefaultSkin', 'isCollapse']),
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  watch: {
    getDefaultColor: {
      handler() {
        document.documentElement.style.setProperty(
          '--color-primary',
          this.getDefaultColor
        );
      },
      immediate: true,
    },
    isDefaultSkin: {
      handler(newVal) {
        this.isSetCommonSkin = newVal;
        document.documentElement.style.setProperty(
          '--color-header-bg',
          newVal ? this.getDefaultColor : GLOBAL.defaultHdColor
        );
      },
      immediate: true,
    },
    $route: {
      handler: function (val) {
        this.activeParentList = [];
        let defaultActive = this.$route.name;
        this.findParentByPid(menus, defaultActive);
        if (!this.activeParentList.length) {
          // console.log(val);
          this.activeParentList = [
            { menuKey: val.name, label: val.meta.label },
          ];
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      toggleSideBar: 'pageConfig/toggleSideBar',
      setDefaultColor: 'pageConfig/setDefaultColor',
      setDefaultHdColor: 'pageConfig/setDefaultHdColor',
    }),
    closeWebsocket(){
      console.log('WebSocket连接已关闭');
      socket.close()
    },
    setMessage() {},
    connectWebSocket() {
      // Create WebSocket connection.
      socket = new WebSocket(
        `wss://hx.hnmyhx.cn/hx/websocket/${this.userInfo.id}/`
      );
      console.log();
      // Connection opened
      socket.addEventListener('open', (event) => {
        // socket.send('Hello Server!');
        console.log(
          'WebSocket connection opened',
          moment().format('YYYY-MM-DD HH:mm:ss') + ' onopen=' + event
        );
        this.timer = setInterval(() => {
          var ping = { type: 'ping' };
          socket.send(JSON.stringify(ping));
        }, 30 * 1000);
      });

      // Listen for messages
      socket.addEventListener('message', (event) => {
        console.log('Message from server ', event.data);

        console.log(event.data);
        const data = JSON.parse(event.data);
        //'消息类型：1：来订单了，2：订单取消了',
        if (data.type == 1) {
          this.$refs.audioVo.currentTime = 0;
          this.$refs.audioVo.play();
          this.$notify({
            title: '成功',
            message: `您有新订单了，请及时处理!`,
            type: 'success',
          });
        } else if (data.type == 2 || data.type == 0) {
          this.$refs.audioVo2.currentTime = 0;
          this.$refs.audioVo2.play();
          this.$notify({
            title: '成功',
            message: `有顾客申请取消了，请及时处理!`,
            type: 'success',
          });
        }

        this.msgdata = event.data;
      });

      socket.addEventListener('close', (event) => {
        console.log('onclose=' + event.code + ' ' + event.reason + ' ' + event.wasClean);
        console.log('WebSocket连接已关闭');
        this.reconnect()
      //   socket.send('Hello Server!');
      //  setInterval(() => {
      //   socket = new WebSocket('wss://hx.hnmyhx.cn/hx/websocket/13/');
      //  }, 15*1000);
      });
    },
    reconnect() {

      if(limitConnect>0){
        limitConnect--
        timeConnect++
        setTimeout(() => {
          this.connectWebSocket()
        }, 5000);
      }
    },
    //根据当前menu pid查找所有的父级
    findParentByPid(menus, key) {
      menus.forEach((item) => {
        // console.log(item.menuKey.indexOf(key));
        if (item.menuKey.indexOf(key) >= 0) {
          this.activeParentList.push(item);
        }
        if (item.children) {
          this.findParentByPid(item.children, key);
        }
      });
    },

    //退出登录
    logout() {
      this.$confirm('是否确认退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          //清除用户信息
          this.$store.dispatch('clearUserInfo').then((res) => {
            // console.log("退出登录");
            this.$router.push({ name: 'login' });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    handleFullScreen() {
      if (!screenfull.isEnabled) {
        return false;
      }
      screenfull.toggle();
    },
    setLayout() {
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },
    changeTheme(item) {
      //存储背景色信息
      this.setDefaultColor(item.color);
    },
    changeHeaderSkin(value) {
      //存储头部背景色信息
      this.setDefaultHdColor(value);
    },
    changeIconSkin(value) {
      //存储头部背景色信息
      this.setDefaultIconColor(value);
    },
    viewInfo() {},
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/index.scss';
.c-header-nav.collapse {
  padding-left: 70px;
}
.c-header-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $--height-header;
  color: $--color-base;
  // color: rgba(255, 255, 255, 0.8);
  background: var(--color-header-bg);
  box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
  padding-left: $--width-sidebar;
  // background: linear-gradient(315deg, #5430ff 0%, #3693ff 100%);
  .c-breadcrumb {
    // height: 75px;
    // line-height: 75px;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  .c-header-nav__popover {
    display: flex;
    cursor: pointer;
  }
  .c-header-nav__warn {
    cursor: pointer;
    margin-right: 30px;
  }
  .c-header-nav__avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .header-layout {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #495057;
    .iconfont {
      padding-left: 20px;
    }
  }
  .u-flex {
    display: flex;
    justify-content: space-between;
  }
  .u-flex-shrink-0 {
    flex-shrink: 0;
  }
  .iconfont {
    font-size: 24px;
    color: #495057;
  }
  .header-menu {
  }
  .c-header__logout {
    cursor: pointer;
    padding-right: 20px;
  }
  .u-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.list-text {
  height: 35px;
  line-height: 35px;
}
.moreset-title {
  padding: 15px 0 0px 20px;
  margin-bottom: 4px;
  color: $--color-black;
}
.select-content {
  padding: 20px 20px 0 20px;
  display: flex;
  .u-flex {
    width: 100%;
    justify-content: space-between;
    align-content: center;
    color: rgba(0, 0, 0, 0.85);
  }
}
.select-color-content {
  padding: 20px 0 0px 0px;
  margin-bottom: 4px;
  .select-color-item {
    background-color: gray;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    float: left;
    margin-left: 20px;
    color: white;
    font-size: 18px;
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    line-height: 30px;
  }
  // .select-color-item.blue {
  //   background-color: #2d8cf0;
  // }
  // .select-color-item.green {
  //   background-color: #36b368;
  // }
  // .select-color-item.origin {
  //   background-color: #f6ad55;
  // }
  // .select-color-item.red {
  //   background-color: #f56c6c;
  // }
  &.deepblue {
    background-color: #3bc0c3;
  }
}

::v-deep {
  .el-icon-bell {
    font-size: 20px;
  }
}
</style>
