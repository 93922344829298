import Vue from 'vue';
import App from './App.vue';
//路由信息
import router from './router';
//缓存信息
import store from './store';
//自定义指令
import '@/utils/directive';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/iconfont/iconfont.css';
import './styles/index.scss';
import '@/components'; // 自定义组件

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$store = store;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
