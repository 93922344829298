<template>
  <div class="l-wrapper">
    <c-header></c-header>
    <div
      class="c-container"
      :class="{
        'c-sidebar--hide': isCollapse,
      }"
    >
      <!--收起-->
      <!-- <BaseSidebar></BaseSidebar> -->
      <!--展开-->
      <Sidebar></Sidebar>
      <div class="c-container__content">
        <!--主要内容-->

        <!-- <Tabs id="c-tabs" ref="cTabs"></Tabs> -->
        <Main></Main>
      </div>
    </div>
  </div>
</template>
<script>
import CHeader from "./components/Header.vue";
import BaseSidebar from "./components/BaseSidebar.vue";
import Sidebar from "./components/Sidebar.vue";
import Tabs from "./components/Tabs.vue";
import Main from "./components/Main.vue";

// import { getMenuList } from "@/api/menu";
import { mapState, mapActions, mapGetters } from "vuex";

import store from "@/store/index";

export default {
  components: { CHeader, Sidebar, BaseSidebar, Tabs, Main },
  created() {
    // getMenuList().then((res) => {
    //   console.log(res);
    // });
  },
  mounted() {},
  computed: {
    // 侧边栏是否默认收起
    ...mapGetters(["isCollapse"]),
    ...mapState({
    }),
  },
  data() {
    return {
      pageHeight: "auto",
      redHeight: "",
    };
  },
  methods: {
    ...mapActions("user", ["getLoginInfo"]),
    changeMenu() {
      this.$refs.refBreadPageHeader &&
        this.$refs.refBreadPageHeader.initBread();
    },
  },
};
</script>
<style lang="scss" >
@import "@/styles/workflow.scss";
</style>
