<template>
  <div class="zui-screw zs-page--stretch zs-page zs-page--flex">
    <div class="zs-page__search zs-page__search--nocheck" v-if="showSearch">
      <slot name="form"> </slot>
    </div>
    <div class="zs-page__content">
      <div class="content--padding zs-page--flex">
        <div class="z-page-header el-page-header content__header">
          <template v-if="showTableTitle">
            <!-- <div class="table_left flex_box"> -->
            <div class="z-page-header__line el-page-header__line"></div>
            <div class="z-page-header__content el-page-header__content">
              <slot name="title">列表</slot>
              <span class="content__header__subtitle">
                <slot name="sub-title"></slot>
              </span>
            </div>
            <!-- <div class="table_header_content">
            <slot name="title">列表</slot>
          </div> -->
            <!-- </div> -->
            <div class="z-page-header__right el-page-header__right">
              <div
                class="z-page-header__description el-page-header__description"
              >
                <slot name="btn"></slot>
              </div>
            </div>
          </template>
        </div>
        <slot name="table"></slot>
        <!-- <div class="content__box s-table area-table">
          <div class="content__body">
            <slot name="table"></slot>
          </div>
          <div class="content__footer"></div>
        </div> -->
      </div>
      <!-- <div class="table_container">
        <div class="table-content"></slot></div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "TableLayout",
  props: {
    showTableTitle: {
      type: Boolean,
      default: true,
    },
    showSearch:{
      type: Boolean,
      default: true,
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/components/table-layout.scss";
</style>
