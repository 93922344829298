import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import NProgress from 'nprogress'
import Utils from '@/utils/utils'
import { constantRouterMap } from './constant'
import { LoaclRouterMap } from './local'
Vue.use(VueRouter)
//固定路由
const routes = [
  {
    path: '/',
    name: 'Layout',
    redirect: '/home',
    component: Layout,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/HomeView.vue'),
        meta: {
          label: '首页',
          cache: true,
          closable: false
        }
      },
      {
        path: 'tableLayout',
        name: 'tableLayout',
        component: () => import('@/views/TableLayout/index.vue'),
        meta: {
          label: '表格列表',
          cache: true,
          closable: true
        }
      },

      // {
      //   path: 'systemSetting',
      //   name: 'systemSetting',
      //   component: () => import('@/views/SystemSetting/Admin/index.vue'),
      //   meta: {
      //     label: "系统设置",
      //     cache: true,
      //     closable: true,
      //   },
      //   children: [
      //     {
      //       path: 'admin',
      //       name: 'admin',
      //       component: () => import('@/views/SystemSetting/Admin/index.vue'),
      //       meta: {
      //         label: "系统用户",
      //         cache: true,
      //         closable: true,
      //       },
      //     },
      //     {
      //       path: 'role',
      //       name: 'role',
      //       component: () => import('@/views/SystemSetting/Role/index.vue'),
      //       meta: {
      //         label: "角色管理",
      //         cache: true,
      //         closable: true,
      //       },
      //     }
      //   ]
      // },
      {
        path: 'store',
        name: 'store',
        component: () => import('@/views/Store/index.vue'),
        meta: {
          label: '门店管理',
          cache: true,
          closable: true
        }
      },
      {
        path: 'storeMap',
        name: 'storeMap',
        component: () => import('@/views/StoreMap/index.vue'),
        meta: {
          label: '门店地图',
          cache: true,
          closable: true
        }
      },
      {
        path: 'product',
        name: 'product',
        component: () => import('@/views/Product/index.vue'),
        meta: {
          label: '商品管理',
          cache: false,
          closable: true
        }
      },

      {
        path: 'order',
        name: 'order',
        component: () => import('@/views/Order/index.vue'),
        meta: {
          label: '订单管理',
          cache: true,
          closable: true
        }
      },

      {
        path: 'afterSales',
        name: 'afterSales',
        component: () => import('@/views/AfterSales/index.vue'),
        meta: {
          label: '售后列表',
          cache: true,
          closable: true
        }
      },
      {
        path: 'coupon',
        name: 'coupon',
        component: () => import('@/views/Coupon/index.vue'),
        meta: {
          label: '活动管理',
          cache: false,
          closable: true
        }
      },
      {
        path: 'activity',
        name: 'activity',
        component: () => import('@/views/Activity/index.vue'),
        meta: {
          label: '公益服务',
          cache: true,
          closable: true
        }
      },
      {
        path: 'distribution',
        name: 'distribution',
        component: () => import('@/views/Distribution/index.vue'),
        meta: {
          label: '分销管理',
          cache: true,
          closable: true
        }
      },

      {
        path: 'finance',
        name: 'finance',
        component: () => import('@/views/Finance/index.vue'),
        meta: {
          label: '财务管理',
          cache: true,
          closable: true
        }
      },
      {
        path: 'recordAccount',
        name: 'recordAccount',
        component: () => import('@/views/Finance/RecordAccount/index.vue'),
        meta: {
          label: '微信对账',
          cache: true,
          closable: true
        }
      },
      {
        path: 'financialRecords',
        name: 'financialRecords',
        component: () => import('@/views/Finance/FinancialRecords/index.vue'),
        meta: {
          label: '资金记录',
          cache: true,
          closable: true
        }
      },
      {
        path: 'emplyeeIncome',
        name: 'emplyeeIncome',
        component: () => import('@/views/Finance/EmplyeeIncome/index.vue'),
        meta: {
          label: '员工收益',
          cache: true,
          closable: true
        }
      },
      {
        path: 'admin',
        name: 'admin',
        component: () => import('@/views/SystemSetting/Admin/index.vue'),
        meta: {
          label: '系统用户',
          cache: true,
          closable: true
        }
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/SystemSetting/Role/index.vue'),
        meta: {
          label: '角色管理',
          cache: true,
          closable: true
        }
      },

      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/SystemSetting/User/index.vue'),
        meta: {
          label: '用户管理',
          cache: true,
          closable: true
        }
      },
      // {
      //   path: 'shopowner',
      //   name: 'shopowner',
      //   component: () => import('@/views/SystemSetting/Shopowner/index.vue'),
      //   meta: {
      //     label: '店长',
      //     cache: true,
      //     closable: true,
      //   },
      // },
      // {
      //   path: 'employee',
      //   name: 'employee',
      //   component: () => import('@/views/SystemSetting/Employee/index.vue'),
      //   meta: {
      //     label: '员工',
      //     cache: true,
      //     closable: true,
      //   },
      // },
      // {
      //   path: 'deliver',
      //   name: 'deliver',
      //   component: () => import('@/views/SystemSetting/Deliver/index.vue'),
      //   meta: {
      //     label: '保姆',
      //     cache: true,
      //     closable: true,
      //   },
      // },
      // {
      //   path: 'nanny',
      //   name: 'nanny',
      //   component: () => import('@/views/SystemSetting/Nanny/index.vue'),
      //   meta: {
      //     label: '保姆',
      //     cache: true,
      //     closable: true,
      //   },
      // },
      // {
      //   path: 'hourlyWorker',
      //   name: 'hourlyWorker',
      //   component: () => import('@/views/SystemSetting/HourlyWorker/index.vue'),
      //   meta: {
      //     label: '小时工',
      //     cache: true,
      //     closable: true,
      //   },
      // },
      {
        path: 'checkUser',
        name: 'checkUser',
        component: () => import('@/views/SystemSetting/CheckUser/index.vue'),
        meta: {
          label: '用户审核',
          cache: true,
          closable: true
        }
      },
      {
        path: 'systemSet',
        name: 'systemSet',
        component: () => import('@/views/SystemSetting/SystemSet/index.vue'),
        meta: {
          label: '小程序和系统设置',
          cache: true,
          closable: true
        }
      },
      {
        path: 'category',
        name: 'category',
        component: () => import('@/views/SystemSetting/Category/index.vue'),
        meta: {
          label: '分类设置',
          cache: true,
          closable: true
        }
      },
      {
        path: 'recharge',
        name: 'recharge',
        component: () => import('@/views/SystemSetting/Recharge/index.vue'),
        meta: {
          label: '充值服务',
          // cache: true,
          closable: true
        }
      },
      {
        path: 'rechargeQuery',
        name: 'rechargeQuery',
        component: () => import('@/views/SystemSetting/RechargeQuery/index.vue'),
        meta: {
          label: '充值查询',
          // cache: false,
          closable: true
        }
      },
      {
        path: 'rechargeCheck',
        name: 'rechargeCheck',
        component: () => import('@/views/SystemSetting/RechargeCheck/index.vue'),
        meta: {
          label: '充值审核',
          // cache: true,
          closable: true
        }
      },
      {
        path: 'integral',
        name: 'integral',
        component: () => import('@/views/SystemSetting/Integral/index.vue'),
        meta: {
          label: '积分记录',
          cache: true,
          closable: true
        }
      },
      {
        path: 'productSalesSummary',
        name: 'productSalesSummary',
        component: () => import('@/views/SystemSetting/ProductSalesSummary/index.vue'),
        meta: {
          label: '商品销售汇总',
          cache: true,
          closable: true
        }
      },
      ...constantRouterMap,
      ...LoaclRouterMap
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  }
]

//处理路由导航守卫
const router = new VueRouter({
  routes
})

//重写push方法，跳转相同路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)

  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  //未匹配到的路由
  if (!to.matched || !to.matched.length) {
    next({ name: 'Error404' })
    return
  }
  // console.log(Utils.getStorage('userInfo'))
  let userInfo = Utils.getStorage('userInfo')
  if (to.path === '/login') {
    next()
    return
  }
  //用户信息存在
  if (!userInfo || Object.keys(userInfo).length === 0) {
    next({ name: 'login' })
    return
  } else {
    next()
    NProgress.done()
    return
  }
})

export default router
