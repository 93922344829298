import { getLoginResources } from '@/api/menu';
import { menus } from '@/api/menu';
import utils from '@/utils/utils';

function rebuildTree(data) {
  const result = []; // 存放结果集
  const itemMap = {}; //
  for (const item of data) {
    const id = item.id;
    const pid = item.pid;

    if (!itemMap[id]) {
      itemMap[id] = {
        children: [],
      };
    }

    itemMap[id] = {
      ...item,
      children: itemMap[id]['children'],
    };

    const treeItem = itemMap[id];

    if (pid === 0) {
      result.push(treeItem);
    } else {
      if (!itemMap[pid]) {
        itemMap[pid] = {
          children: [],
        };
      }
      itemMap[pid].children.push(treeItem);
    }
  }
  return result;
}
const Types = {
  SET_MENU: 'SET_MENU',
};
// const rm = new RouteMenu()

const menu = {
  state: {
    activeMenuInfo: { activeMenuObj: {}, menuList: [] },
    menus: [],
  },
  actions: {
    getMenu({ commit }, menus) {
      return new Promise(async (resolve, rejct) => {
        const res = await getLoginResources();
        if (res.status) {
          //将数组变成层级树
          res.data =
            res.data &&
            res.data.map((item) => ({
              ...item,
              menuName: item.label,
              label: item.label,
              // menuKey: item.path,
              // hidden: item.hidden === 0,
            }));
          let menus = res.data;
          
          // menus.push({
          //   id: 140,
          //   menuName: '充值查询',
          //   path: 'views/Finance/RechargeQuery/index',
          //   sort: 0,
          //   label: '充值查询',
          //   hidden: 0,
          //   menuKey: 'rechargeQuery',
          //   parentIds: '14',
          //   icon: null,
          //   children: null,
          //   pid: 14,
          // });
          // menus.push({
          //   id: 150,
          //   menuName: '充值审核',
          //   path: 'views/Finance/RechargeCheck/index',
          //   sort: 0,
          //   label: '充值审核',
          //   hidden: 0,
          //   menuKey: 'rechargeCheck',
          //   parentIds: '14',
          //   icon: null,
          //   children: null,
          //   pid: 14,
          // });
          // menus.push({
          //   id: 120,
          //   menuName: '积分记录',
          //   path: 'views/SystemSetting/Integral/index',
          //   sort: 0,
          //   label: '积分记录',
          //   hidden: 0,
          //   menuKey: 'integral',
          //   parentIds: '14',
          //   icon: null,
          //   children: null,
          //   pid: 14,
          // });
          menus.push({
            id: 190,
            menuName: '微信对账',
            path: 'views/Finance/RecordAccount/index',
            sort: 0,
            label: '微信对账',
            hidden: 0,
            menuKey: 'recordAccount',
            parentIds: '11',
            icon: null,
            children: null,
            pid: 11,
          });
          // menus.push({
          //   id: 191,
          //   menuName: '门店地图',
          //   path: 'views/StoreMap/index',
          //   sort: 0,
          //   label: '门店地图',
          //   hidden: 0,
          //   menuKey: 'storeMap',
          //   parentIds: '0',
          //   icon: 'el-icon-map-location',
          //   children: null,
          //   pid: 0,
          // });
          // menus.push({
          //   id: 110,
          //   menuName: '充值服务',
          //   path: 'views/Finance/Recharge/index',
          //   sort: 0,
          //   label: '充值服务',
          //   hidden: 0,
          //   menuKey: 'recharge',
          //   parentIds: '14',
          //   icon: null,
          //   children: null,
          //   pid: 14,
          // });
          menus = rebuildTree(res.data);
          commit('SET_MENU', menus);
          resolve(menus);
        }
        // commit('SET_MENU', menus);
        // resolve(menus);
      });
    },
  },
  mutations: {
    //设置菜单
    [Types.SET_MENU]: (state, data) => {
      state.menus = data;
    },
  },
};
export default menu;
