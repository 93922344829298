let menus = [
  {
    menuName: '门店管理',
    menuKey: 'store',
    hidden: false,
    label: '门店管理',
    path: 'views/Store/index',
    icon: 'el-icon-s-shop',
  },
  {
    menuName: '门店地图',
    menuKey: 'storeMap',
    hidden: false,
    label: '门店地图',
    path: 'views/StoreMap/index',
    icon: 'el-icon-s-shop',
  },
  {
    menuName: '商品管理',
    menuKey: 'productSet',
    hidden: false,
    label: '商品管理',
    icon: 'el-icon-sell',
    children: [
      {
        menuName: '商品管理',
        menuKey: 'product',
        hidden: false,
        label: '商品管理',
        path: 'views/Product/index'
      },
      {
        menuName: '商品分类',
        menuKey: 'category',
        hidden: false,
        label: '商品分类',
        path: 'views/SystemSetting/Category/index'
      },
      {
        menuName: '商品采购单',
        menuKey: 'productSalesSummary',
        hidden: false,
        label: '商品采购单',
        path: 'views/SystemSetting/ProductSalesSummary/index'
      }
    ]
  },
  {
    menuName: '订单管理',
    menuKey: 'order',
    hidden: false,
    label: '订单管理',
    path: 'views/Order/index',
    icon: 'el-icon-s-order',
    children: [
      {
        menuName: '订单列表',
        menuKey: 'order',
        hidden: false,
        label: '订单列表',
        path: 'views/Order/index',
        icon: 'el-icon-s-order'
      },
      {
        menuName: '售后列表',
        menuKey: 'afterSales',
        hidden: false,
        label: '售后列表',
        path: 'views/AfterSales/index',
        icon: 'el-icon-service'
      }
    ]
  },
  {
    menuName: '活动管理',
    menuKey: 'coupon',
    hidden: false,
    label: '活动管理',
    path: 'views/Coupon/index',
    icon: 'el-icon-s-ticket'
  },
  {
    menuName: '公益服务',
    menuKey: 'activity',
    hidden: false,
    label: '公益服务',
    path: 'views/activity/index',
    icon: 'el-icon-s-ticket'
  },
  // {
  //   menuName: '分销管理',
  //   menuKey: 'distribution',
  //   hidden: false,
  //   label: '分销管理',
  //   path: 'views/Distribution/index',
  //   icon: 'el-icon-s-marketing',
  // },
  {
    menuName: '财务管理',
    menuKey: 'finance',
    hidden: false,
    label: '财务管理',
    path: 'views/Finance/index',
    icon: 'el-icon-s-finance',
    children: [
      {
        menuName: '分销管理',
        menuKey: 'distribution',
        hidden: false,
        label: '分销管理',
        path: 'views/Distribution/index'
        // icon: 'el-icon-s-marketing',
      },
      {
        menuName: '资金记录',
        menuKey: 'financialRecords',
        hidden: false,
        label: '资金记录',
        path: 'views/Finance/FinancialRecords/index'
      },
      {
        menuName: '员工工资',
        menuKey: 'emplyeeIncome',
        hidden: false,
        label: '员工工资',
        path: 'views/Finance/EmplyeeIncome/index'
      },
      {
        menuName: '微信对账',
        menuKey: 'recordAccount',
        hidden: false,
        label: '微信对账',
        path: 'views/Finance/RecordAccount/index'
      }
    ]
  },
  {
    menuName: '充值管理',
    menuKey: 'recharge',
    hidden: false,
    label: '充值管理',
    path: 'views/Recharge/index',
    icon: 'el-icon-s-finance',
    children: [
      {
        menuName: '充值服务',
        menuKey: 'recharge',
        hidden: false,
        label: '充值服务',
        path: 'views/SystemSetting/Recharge/index'
      },
      {
        menuName: '充值审核',
        menuKey: 'rechargeCheck',
        hidden: false,
        label: '充值审核',
        path: 'views/SystemSetting/RechargeCheck/index'
      },

      {
        menuName: '充值查询',
        menuKey: 'rechargeQuery',
        hidden: false,
        label: '充值查询',
        path: 'views/SystemSetting/RechargeQuery/index'
      },
      {
        menuName: '充值配置',
        menuKey: 'rechargeConfig',
        hidden: false,
        label: '充值配置',
        path: 'views/SystemSetting/RechargeConfig/index'
      }
    ]
  },
  {
    menuName: '系统管理',
    menuKey: 'systemSetting',
    hidden: false,
    label: '系统管理',
    path: '',
    icon: 'el-icon-setting',
    children: [
      {
        menuName: '角色管理',
        menuKey: 'role',
        hidden: false,
        label: '角色管理',
        path: 'views/SystemSetting/Role/index'
      },
      // {
      //   menuName: '管理员设置',
      //   menuKey: 'admin',
      //   hidden: false,
      //   label: '管理员设置',
      //   path: 'views/SystemSetting/Admin/index',
      // },
      {
        menuName: '用户管理',
        menuKey: 'user',
        hidden: false,
        label: '用户管理',
        path: 'views/SystemSetting/User/index'
      },
      // {
      //   menuName: '店长',
      //   menuKey: 'shopowner',
      //   hidden: false,
      //   label: '店长',
      //   path: 'views/SystemSetting/Shopowner/index',
      // },
      // {
      //   menuName: '员工',
      //   menuKey: 'employee',
      //   hidden: false,
      //   label: '员工',
      //   path: 'views/SystemSetting/Employee/index',
      // },
      // {
      //   menuName: '配送员',
      //   menuKey: 'deliver',
      //   hidden: false,
      //   label: '配送员',
      //   path: 'views/SystemSetting/Deliver/index',
      // },
      // {
      //   menuName: '保姆',
      //   menuKey: 'nanny',
      //   hidden: false,
      //   label: '保姆',
      //   path: 'views/SystemSetting/Nanny/index',
      // },
      // {
      //   menuName: '小时工',
      //   menuKey: 'hourlyWorker',
      //   hidden: false,
      //   label: '小时工',
      //   path: 'views/SystemSetting/HourlyWorker/index',
      // },
      {
        menuName: '用户审核',
        menuKey: 'checkUser',
        hidden: false,
        label: '用户审核',
        path: 'views/SystemSetting/CheckUser/index'
      },
      {
        menuName: '小程序&系统设置',
        menuKey: 'systemSet',
        hidden: false,
        label: '小程序&系统设置',
        path: 'views/SystemSetting/SystemSet/index'
      },

      {
        menuName: '积分记录',
        menuKey: 'integral',
        hidden: false,
        label: '积分记录',
        path: 'views/SystemSetting/Integral/index'
      }
    ]
  }
  // {
  //   menuName: '菜单',
  //   menuKey: 'Menus',
  //   hidden: false,
  //   label: "菜单",
  //   path: "views/HomeView",
  //   icon: "el-icon-location",
  //   children: [
  //     {
  //       menuName: 'TableLayout',
  //       label: "TableLayout",
  //       menuKey: 'tableLayout',
  //       path: "views/TableLayout/index",
  //       icon: "el-icon-location",
  //       hidden: false,
  //     },
  //   ],
  // },
  // {
  //   menuName: '错误页面',
  //   label: "错误页面",
  //   menuKey: 'ErrorPage',
  //   path: "views/Error/Common",
  //   icon: "el-icon-location",
  //   hiidden: false,
  //   children: [
  //     {
  //       menuName: '403',
  //       label: "403",
  //       menuKey: 'Error403',
  //       path: "views/Error/403",
  //       icon: "el-icon-location",
  //       hiidden: false,
  //     },
  //     {
  //       menuName: '404',
  //       label: "404",
  //       menuKey: 'Error404',
  //       path: "views/Error/404",
  //       icon: "el-icon-location",
  //       hiidden: false,
  //     },
  //     {
  //       menuName: '500',
  //       label: "500",
  //       menuKey: 'Error500',
  //       path: "views/Error/500",
  //       icon: "el-icon-location",
  //       hiidden: false,
  //     },
  //   ],
  // },
]
let idIndex = 1
function createMenuId(menus, pId) {
  menus.forEach(item => {
    item['id'] = idIndex
    item['pId'] = pId
    if (item.children) {
      ++idIndex
      createMenuId(item.children, item.id)
    } else {
      idIndex++
    }
  })

  return menus
}

menus = createMenuId(menus, 0)

console.log(JSON.stringify(menus))
export { menus }
