<template>
  <el-radio-group v-model="inputValue">
    <el-radio
      :label="item.value"
      v-for="(item, index) in options"
      :key="index"
      >{{ item.label }}</el-radio
    >
  </el-radio-group>
</template>
<script>
export default {
  name: "HRadio",
  props: {
    value: {
      type: [String, Number],
    },
    options: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    inputValue() {
      return this.formData[this.$attrs.prop];
    },
  },
  // watch: {
  // 	value(newVal) {
  // 		this.inputValue = newVal
  // 	}
  // }
};
</script>
