<template>
  <el-row style="display: flex; flex-wrap: wrap">
    <el-col :span="item.span ? span * item.span : span" v-for="(item, index) in realColumns" :key="index">
      <el-form-item size="small" :label="item.label + ':'" :prop="item.prop" :label-width="item.labelWidth">
        <span slot="label" v-if="item.slotHeader">
          <slot :name="`${item.prop}Header`"></slot>
        </span>
        <slot :name="item.prop" v-if="item.showSlot"></slot>
        <component
          style="width: 100%"
          v-else
          :formData="formData"
          v-model="formData[item.prop]"
          :is="componentType(item.itemType)"
          :clearable="item.clearable || true"
          :placeholder="`请输入${item.label}`"
          v-bind="{ ...item }"
        ></component> </el-form-item
    ></el-col>
    <el-col
      :span="
        realColumns.length % 4 === 1 ? 18 : realColumns.length % 4 === 2 ? 12 : realColumns.length % 4 === 0 ? 24 : 6
      "
    >
      <div class="flex_box">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="onSearch">搜索 </el-button>
        <el-button size="mini" @click="onReset">重置</el-button>
        <span class="no-border" @click="changeCollapse" v-if="configs.length > 3 && isShowExpand"
          >{{ isExpand ? '展开' : '收起'
          }}<i :class="[isExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-up', 'el-icon--right']"></i
        ></span>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'FormItems',
  props: {
    configs: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object,
      default: () => {}
    },
    span: {
      type: Number,
      default: 6
    },
    //是否展示折叠，form-items上配置
    isCollapse: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isExpand: false
    }
  },
  computed: {
    realColumns() {
      let columns = []
      if (this.isExpand) {
        columns = cloneDeep(this.configs).filter(item => {
          return !item.isCollapse
        })
      } else {
        columns = cloneDeep(this.configs)
      }
      return columns
    },
    //判断没有折叠配置则不展示折叠按钮
    isShowExpand() {
      let columns = cloneDeep(this.configs).filter(item => {
        return item.isCollapse
      })
      return columns.length > 0
    }
  },
  created() {},
  methods: {
    componentType(itemType) {
      if (typeof itemType === 'string') {
        return `${itemType}`
      }

      return itemType
    },
    changeCollapse() {
      this.isExpand = !this.isExpand
    },
    onSearch() {
      this.$emit('onSearch')
    },
    onReset() {
      this.$emit('onReset')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables/colors.scss';
.flex_box {
  justify-content: flex-end;
  align-items: center;
}
.el-button--small {
  margin-left: 10px;
  font-size: 14px;
}

::v-deep {
  .el-row {
    display: flex;
    flex-wrap: wrap;
  }
  .no-border {
    display: inline-block;
    border: none;
    padding: 9px 15px;
    margin-left: 10px;
    color: var(--color-primary);
    font-size: 12px;
  }
}
</style>
